@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Thin.otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Extrabold.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../../public/fonts/ProximaNova-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: Proxima Nova, sans-serif !important;
}

.btn {
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.btnMain {
  background-color: #03d5c0;
  color: #000000;
}

.btnError {
  background-color: #121619;
  color: #878d96;
  box-shadow: none;
  border: 1px solid transparent;
  outline: none;
  pointer-events: none;
}

.btnCosolidate {
  background: #000000;
  border: 2px solid #03d5c0;
  transition: all 0.3s;
  width: 100%;
  padding: 0 40px;
}

.btnCosolidate:hover {
  background-color: #12ebdc;
}

.btnPurple {
  background-color: #7d00ff;
}

.btn-buy-sell-moonpay {
  border: 2px solid #7d00ff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  width: 100%;
  margin-top: 20px;
  transition: all 0.2s;
  cursor: pointer;
}

.btn-buy-sell-moonpay:hover {
  background-color: #7d00ff;
}

.moonpay-token:hover {
  background-color: #2f2e41;
}

.list-tokens {
  max-height: 800px;
  overflow-y: auto;
  background-color: #0f0f19;
  height: 100%;
  border-radius: 0 0 10px 10px;
}

.label-text {
  color: #999999;
}

.link:hover {
  text-decoration: underline;
}

.link-color {
  color: #03d5c0;
  transition: all 0.3s;
}

.link-color:hover {
  text-decoration: underline;
}

.bg-black {
  background-color: #0f0f19;
  height: 100%;
}

.text-gray {
  color: #bbbcbc;
}

.bg-gray {
  background-color: #1e1c2d;
}

.bg-active-tab {
  background-color: #46455f;
}

.border-dark {
  border-color: #28263a;
}

.tooltip-wrapper {
  background-color: #28263a;
  border-radius: 10px;
  padding: 15px;
  width: 185px;
}

.network-select {
  background: #28263a;
  border: 1px solid #45405b;
  border-radius: 8px;
  padding: 0;
  position: absolute;
  top: 56px;
  width: 210px;
  left: -20px;
  overflow: hidden;
}

.tooltip-wrapper-large {
  width: 200px;
}

.main-text {
  color: #edf7f6;
}

.secondary-text {
  color: #d3d3d3;
}

.label-text2 {
  color: #8e8ea0;
}

.gray-text {
  color: #eaeaea;
}

.border-gray {
  border-color: #8e8ea0;
}

.yellow-text {
  color: #f3ba2f;
}

.menu-item-search {
  position: relative;
}

.token-info {
  display: none;
}

.menu-item-search:hover .token-info {
  display: block;
  position: fixed;
  right: -500px;
  top: 0;
}

.chain-item:hover {
  background: linear-gradient(0deg, #2f2e41, #2f2e41), #0f0f19;
}

.bridgeTitle {
  font-weight: 700;
  font-size: 22px;
  color: #edf7f6;
  line-height: 40px;
  margin-bottom: 20px;
}

.border-main {
  border-color: #03d5c0;
}

.btnClose {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.dropdown-wrapper {
  background-color: #000000;
  border-radius: 10px;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  border: 2px solid #03d5c0;
}

.consolidate:hover {
  color: #03d5c0 !important;
  text-decoration: none !important;
}

.link-dropdown:hover {
  color: #03d5c0 !important;
  text-decoration: underline;
}

.info-button {
  opacity: 0.5;
  cursor: pointer;
}

.info-button:hover {
  opacity: 1;
}

.text-white {
  color: #ffffff;
}

.priceusdvalue {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}

.priceusdvalue2 {
  max-width: 260px;
}

@media (max-width: 767px) {
  .list-tokens {
    max-height: calc(100% - 150px);
    overflow-y: auto;
  }

  .btn-buy-sell-moonpay:hover {
    background-color: transparent;
  }

  .network-select {
    width: 185px;
    padding: 0;
  }

  .priceusdvalue {
    max-width: 220px;
  }

  .priceusdvalue2 {
    max-width: 190px;
  }
}

.text-tiny {
  font-size: 12px;
}
.text-small {
  font-size: 14px;
}

.text-big {
  font-size: 18px;
}
.text-huge {
  font-size: 24px;
}

.background-main {
  background-color: #28263a;
}

.border-bottom {
  border-bottom: 1px solid #45405b;
}
