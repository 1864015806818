.wrapper {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.switch {
  width: 56px;
  height: 32px;
  margin-right: 10px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  background-color: #008a81;
}

.switchEmpty {
  margin-right: 0;
}
.active:after {
  content: '';
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  left: auto !important;
}

.inActive {
  background-color: #b4b4b4;
}

.inActive:after {
  content: '';
  width: 26px;
  height: 26px;
  display: block;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
}

.switch-small {
  width: 40px;
  height: 22px;
  margin-right: 0;
}

.switch-small:after {
  width: 20px;
  height: 20px;
  left: 1px;
}

.switch-small.active:after {
  right: 1px;
}
