.videoWrapper {
  /* position: relative; */
  /* padding-top: 56.25%;  */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
}

.videoWrapper .reactPlayer {
  max-width: 1500px;
  position: relative;
  z-index: 3;
}

.bg-video {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background-color: #000000;
}

.bg-video-mobile {
  background-color: #28263a;
}

@media (max-width: 1024px) {
  .videoWrapper {
    width: auto;
    height: auto;
    padding-top: 0;
    /* display: none; */
  }

  .videoWrapper .reactPlayer {
    width: auto !important;
    height: auto !important;
  }
  .videoWrapper .reactPlayer video {
    width: 1500px !important;
    height: 1000px !important;
    max-width: 1500px !important;
  }
}
